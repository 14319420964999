import * as React from 'react'
import * as ReactDOM from 'react-dom';
import { sanitize } from 'dompurify';

type DonateRedirectMode = "first box" | "bottom";

interface IDonateRedirectProps {
    donateRedirectTitle: string
    donateRedirectAmounts: number[]
    donateRedirectLink: string
    donateRedirectColourTheme: string
    donateRedirectOffset: number
    heroBanner?: boolean
    donateRedirectSubtext: HTMLElement
    mode: DonateRedirectMode
}

export const cleanHtml = (html: HTMLElement)=> {
    let clean = sanitize(html);
    return { __html: clean }
}

export const DonateRedirectComponent = (props: IDonateRedirectProps) => {
    const [donateValue, setDonateValue] = React.useState(undefined)
    const [error, setError] = React.useState(false);

    const color = `#${props.donateRedirectColourTheme}`

    let theme = "" as string;

    switch (props.donateRedirectColourTheme) {
        case "ffffff":
            theme = "#30343d"
            break;
        default:
            theme = "white"
    }

    let submitTheme = props.donateRedirectColourTheme == "de2b34" || props.donateRedirectColourTheme == "transparent" ? "rgba(0,0,0,0.2)" : "rgba(0,0,0,0.4)"

    const handleSubmit = (e: any) => {
        e.preventDefault();
        window.location.href = `${props.donateRedirectLink[props.donateRedirectLink.length - 1] == "/" ? props.donateRedirectLink.substring(0, props.donateRedirectLink.length - 1) : props.donateRedirectLink}?amount=${donateValue}`
    }
    const cantSubmit = error || donateValue === "" || donateValue == undefined;

    const handleInput = (e: any) => {
        const re = /[0-9]+/
        const isNum = re.test(String(e.target.value))
        if (isNum) {
            setDonateValue(e.target.value)
            setError(false)
        } else {
            setDonateValue(undefined)
            setError(true)
        }
    }

    let styles = {"marginTop":`${props.donateRedirectOffset}px`};
    if(props.donateRedirectColourTheme != "transparent"){
        styles = {...styles, ...{backgroundColor: color}}
    } 
    return (
        <div className={props.heroBanner ? "donate-redirect-wrapper donate-redirect-block" : "donate-redirect-wrapper donate-redirect-flex"} 
                style={styles}>
            <h3 className={props.heroBanner ? "donate-redirect-title-block" : "donate-redirect-title"}>{props.donateRedirectTitle}</h3>
            {props.mode == "first box" && <hr className="donate-redirect-vertical"/>}
            <div className="donate-redirect-subtext" dangerouslySetInnerHTML={cleanHtml(props.donateRedirectSubtext)} />
            <form className="donate-redirect-form" onSubmit={handleSubmit}>
                <div style={{ visibility: "hidden" }}><p>spacer</p></div>
                <div className="donate-redirect-amounts">
                    {props.donateRedirectAmounts.map((a: number, i: number) => {
                        return <button style={{ color: color }} className="donate-redirect-btn" type="button" key={i} onClick={handleInput} value={a}>${a}</button>
                    })}
                </div>
                <div className="donate-redirect-other">

                    <input className="donate-redirect-other-amount" type="text" name="amount" value={donateValue} placeholder="Other Amount" onChange={handleInput} />
                    <input disabled={cantSubmit} style={cantSubmit ? { backgroundColor: submitTheme, cursor: "not-allowed" } : { backgroundColor: submitTheme }} className={cantSubmit ? "donate-redirect-submit" : "donate-redirect-submit donate-redirect-submit-allowed "} type="submit" value="Donate Now" />
                    <div style={error ? { visibility: "visible" } : { visibility: "hidden" }}><p>Please enter a valid amount</p></div>
                </div>
            </form>
        </div>
    )
}

function MountDonateRedirectComponent(props: IDonateRedirectProps, el: Element) {
    ReactDOM.render(
        <DonateRedirectComponent {...props} />, el
    )
}

(window as any).MountDonateRedirectComponent = MountDonateRedirectComponent;